import axios from "axios";
import usersApi from "./modules/users";
import publicApi from "./modules/public";
import marketplaceApi from "./modules/marketplace";
import store from "@/store";

const apiUsersGroup = import.meta.env.VITE_API_GROUP_USERS;
const apiPublicGroup = import.meta.env.VITE_API_GROUP_PUBLIC;
const apiMarketplaceGroup = import.meta.env.VITE_API_GROUP_MARKETPLACE;

// Upewnij się, że URL nie zawiera podwójnych slashy
const normalizeUrl = (baseUrl, group) => {
  const url = `${baseUrl}/${group}`.replace(/([^:]\/)\/+/g, "$1");
  return url;
};

const baseUrlUsers = normalizeUrl(
  import.meta.env.VITE_API_BASE_URL,
  apiUsersGroup
);
const baseUrlPublic = normalizeUrl(
  import.meta.env.VITE_API_BASE_URL,
  apiPublicGroup
);
const baseUrlMarketplace = normalizeUrl(
  import.meta.env.VITE_API_BASE_URL,
  apiMarketplaceGroup
);

// Debug log dla sprawdzenia URL-i
console.log("API URLs:", {
  users: baseUrlUsers,
  public: baseUrlPublic,
  marketplace: baseUrlMarketplace,
});

const defaultHeaders = {
  "Content-Type": "application/json",
};

// Dodaj nagłówki dla środowiska deweloperskiego
if (import.meta.env.DEV || import.meta.env.VITE_DATA_SOURCE === "development") {
  defaultHeaders["X-Data-Source"] = "development";
  defaultHeaders["X-Branch"] = import.meta.env.VITE_API_BRANCH || "dev";
}

const apiUsers = axios.create({
  baseURL: baseUrlUsers,
  headers: defaultHeaders,
});

const apiPublic = axios.create({
  baseURL: baseUrlPublic,
  headers: defaultHeaders,
});

const apiMarketplace = axios.create({
  baseURL: baseUrlMarketplace,
  headers: defaultHeaders,
});

// Add interceptor to apiUsers to automatically add the auth token
apiUsers.interceptors.request.use((config) => {
  const authToken = store.state.user.authToken;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

const addLoggingInterceptors = (instance, name) => {
  instance.interceptors.request.use(
    (config) => {
      if (import.meta.env.DEV) {
        console.info(
          `API Call: ${config.method.toUpperCase()} ${config.url}`,
          config.params,
          config.data,
          config.headers
        );
      }
      return config;
    },
    (error) => {
      if (import.meta.env.DEV) {
        console.error(`API ${name} Request Error:`, error);
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      if (import.meta.env.DEV) {
        console.info(
          `API Response: ${response.config.method.toUpperCase()} ${
            response.config.url
          }`,
          response.status,
          response.data
        );
      }
      return response;
    },
    (error) => {
      if (import.meta.env.DEV) {
        console.error(
          `API ${name} Response Error:`,
          error.response ? error.response.data : error.message
        );
      }
      return Promise.reject(error);
    }
  );
};

// Dodaj interceptory tylko w trybie deweloperskim
if (import.meta.env.DEV) {
  addLoggingInterceptors(apiUsers, "Users");
  addLoggingInterceptors(apiPublic, "Public");
  addLoggingInterceptors(apiMarketplace, "Marketplace");
}

export default {
  users: usersApi(apiUsers),
  public: publicApi(apiPublic),
  marketplace: marketplaceApi(apiMarketplace),
};
