export default {
  calendar: {
    addToGoogle: "Add to Google Calendar",
    addToICal: "Add to iCal",
  },
  passes: {
    validUntil: "Valid until",
    details: {
      entries: "entries",
      remaining: "Remaining",
      value: "Value",
      validity: "Validity",
      days: "days",
      type: "Type",
      types: {
        amount: "Value-based",
        value: "Entry-based",
      },
      duration: "Duration",
      price: "Price",
      availableHours: "Available hours",
    },
  },
  menu: {
    courts: {
      label: "Courts",
      icon: "🎾",
    },
    classes: {
      label: "Classes",
      icon: "👥",
    },
    trainers: {
      label: "Trainers",
      icon: "👨‍🏫",
    },
    passes: {
      label: "Passes",
      icon: "🎫",
    },
  },
  meta: {
    courts: {
      title: "Court Reservations | Playmore",
      description:
        "Book tennis, squash, or badminton courts. Check availability and prices at sports facilities in your area.",
    },
    classes: {
      title: "Fitness Classes | Playmore",
      description:
        "Sign up for fitness classes, yoga, pilates, and other group activities. Check schedule and availability.",
    },
    trainers: {
      title: "Trainer Profile | Playmore",
      description:
        "Manage your trainer profile, availability, and reservations. Check upcoming classes and training sessions.",
    },
  },
  common: {
    loading: {
      classes: "Loading classes...",
      trainers: "Loading trainers...",
      passes: "Loading passes...",
      data: "Loading data...",
    },
    errors: {
      fetchClasses: "Failed to load classes list",
      fetchTrainers: "Failed to load trainers list",
      fetchPasses: "Failed to load passes list",
      fetchVenue: "Failed to load venue data",
      availability: "Failed to check availability",
      createNotification: "Failed to create notification",
      noCourts: "No courts available at selected time",
      carnet: {
        insufficientFunds:
          "Insufficient pass value. You can reduce the reservation price by using discount cards.",
        expired: "Pass has expired",
        notFound: "Pass not found",
      },
    },
    filters: {
      allTrainers: "All trainers",
      allClasses: "All classes",
      allVenueSportObjects: "Select object",
    },
    notifications: {
      notify: "Notify me",
      willNotify: "We'll notify you",
      modal: {
        createTitle: "Create notification?",
        createMessage:
          "Would you like to receive a notification when this time slot becomes available?",
        successTitle: "Notification created!",
        successMessage:
          "We'll notify you when this time slot becomes available.",
      },
    },
  },
  classes: {
    header: {
      trainerTBA: "Trainer to be announced",
    },
    tile: {
      duration: "min",
      participants: {
        occupied: "occupied",
      },
    },
    payment: {
      required: "Payment required",
      timeLimit:
        "To confirm your reservation, please complete the payment within 30 minutes. After this time, the reservation will be automatically cancelled.",
      methods: {
        title: "Payment method",
        onSite: "Pay on site",
        carnet: "Pass",
        online: "Online payment",
      },
      online: {
        title: "Select online payment method",
        blik: "BLIK",
        transfer: "Bank transfer",
        paymentInitiation: "Bank transfer",
        106: "Bank transfer",
        509: "BLIK",
      },
    },
    carnets: {
      title: "Your passes",
      remaining: "Remaining",
      entries: "entries",
      amount: "PLN",
    },
    partnerCard: {
      question: "Do you have a partner card?",
      yes: "Yes",
      no: "No",
    },
    price: {
      base: "Base price",
      partnerCard: "Partner card",
      serviceFee: {
        title: "Service fee",
        tooltip:
          "The fee covers operational costs related to payment processing, including payment operator commissions, administrative costs, and reservation system maintenance.",
      },
      total: "Total price",
    },
    buttons: {
      payment: "Proceed to payment",
      close: "Close",
      cancel: "Cancel",
      reserve: "Reserve",
    },
    loading: "Loading classes...",
  },
  courts: {
    steps: {
      sport: {
        title: "Select sport",
        number: "1",
      },
      court: {
        title: "Select court type",
        number: "2",
        select: "Select court",
      },
    },
    duration: {
      label: "Reservation length",
      options: {
        60: "60 minutes",
        90: "90 minutes",
        120: "120 minutes",
      },
    },
    timezone: "All times are shown in {timezone} timezone",
    priceList: {
      title: "Select price list",
    },
    partnerCard: {
      title: "Number of discount cards",
      discount: "Discount cards",
    },
  },
  passes: {
    details: {
      name: "Name",
      validity: "Validity",
      serviceFee: {
        title: "Service fee",
        tooltip:
          "The fee covers operational costs related to payment processing, including payment operator commissions, administrative costs, and reservation system maintenance.",
      },
      total: "Total price",
      days: "days",
      value: "Value",
      type: "Type",
      types: {
        amount: "Value-based",
        value: "Entry-based",
        entries: "entries",
      },
      entries: "entries",
      remaining: "Remaining",
      duration: "Duration",
      price: "Price",
      availableHours: "Available hours",
    },
    actions: {
      buy: "Buy pass",
    },
    empty: "No passes available",
    legal: {
      text: "By placing an order, the User declares that they have read and accept the terms and conditions and privacy policy of the selected sports facility, as well as",
      terms: "Playmore terms of service",
      privacy: "privacy policy",
      and: "and",
    },
  },
  trainers: {
    details: {
      availability: "Availability",
    },
  },
  details: {
    door: {
      title: "Facility Access",
      paymentRequired:
        "To access the facility, you need to complete the payment first.",
      openButton: "Open facility door",
      success: "Door has been opened",
      error: "Failed to open the door. Please try again.",
    },
  },
};
